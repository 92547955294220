<!--
 * @Description: 门店人员
 * @Author: 琢磨先生
 * @Date: 2022-05-20 23:14:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-21 10:42:16
-->

<template>
  <el-drawer
    :title="title"
    v-model="visible"
    direction="rtl"
    size="800px"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="beforeClose"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    custom-class="custom"
  >
    <div style="margin-bottom: var(--el-main-padding)">
      <el-button type="primary" size="small" @click="addEmp" round icon="plus"
        >添加人员</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading">
      <el-table-column label="编号" prop="id" width="100"></el-table-column>
      <el-table-column label="姓名" width="180">
        <template #default="scope">
          <span>{{ scope.row.chs_name }}</span>
          <el-tag v-if="scope.row.id == stop.master_id" size="small"
            >店长</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="mobile_phone"></el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-popconfirm
            title="去定要移除？"
            v-if="scope.row.id != stop.master_id"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button type="text" size="small" class="text-danger"
                >移除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>
  <el-dialog
    title="添加门店人员"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="人员" prop="emp_id">
        <el-select
          v-model="form.emp_id"
          remote
          clearable
          filterable
          reserve-keyword
          placeholder="请输入姓名、手机号搜索"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in empOptions"
            :key="item.id"
            :label="item.chs_name"
            :value="item.id"
          >
            {{ item.chs_name }}{{ item.mobile_phone }}
          </el-option>
        </el-select>
        <div class="help-block">
          <el-icon><WarningFilled /></el-icon>搜索后需选择相应人员
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
      <el-button @click="dialogVisible = false" :disabled="saving"
        >取消</el-button
      >
    </template>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      title: "",
      visible: false,
      loading: false,
      dialogVisible: false,
      saving: false,
      tableData: [],
      empOptions: [],
      stop: {},
      form: {},
      rules: {
        emp_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload"],
  created() {},
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.visible = true;
          this.form = {};
          this.stop = Object.assign({}, this.item);
          this.title = "[" + this.item.name + "]人员";
          this.loadData();
        }
      },
    },
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.get(`seller/v1/stop/emp?id=${this.stop.id}`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 关闭
     */
    beforeClose(done) {
      done();
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 移除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get(
          `seller/v1/stop/remove-emp?empId=${item.id}&stopId=${this.stop.id}`
        )
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.loadData();
          }
        });
    },
    /**
     * 添加人员
     */
    addEmp() {
      this.dialogVisible = true;
    },

    /**
     * 搜索人员
     */
    remoteMethod(q) {
      this.loading = true;
      this.$http.get("seller/v1/emp/select?q=" + q).then((res) => {
        if (res.code == 0) {
          this.empOptions = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 提交数据
     */
    onSubmit() {
      this.form.stop_id = this.stop.id;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/stop/import-emp", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
                this.dialogVisible = false;
              }
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style>
.custom .el-drawer__body {
  padding-top: 0;
}
</style>